import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ReportService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/report';
    }
    getSalesReports(data={},index = null) {
        let url = `${this.#api}/sales`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getAllRefundReports(data={},index = null) {
        let url = `${this.#api}/refund`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportRefundToCsv(data){
        let url = `${this.#api}/refund/export?start_date=${data.start_date}&end_date=${data.end_date}&score_id=${data.score_id}`;
        window.open(url, "_blank");
    }

    getGradingReports(data={},index = null) {
        let url = `${this.#api}/grading`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getMarkingReports(data={},index = null) {
        let url = `${this.#api}/marking`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportSalesToCsv(data={}){
        let url = `${this.#api}/sales/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportScoreSalesToCsv(data={}){
        let url = `${this.#api}/sales/export/score`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    exportScoreExamReportToCsv(data={}){
        let url = `${this.#api}/exam-report/export/score`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    exportGradingToCsv(data={}){
        let url = `${this.#api}/grading/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportMarkingToCsv(data={}){
        let url = `${this.#api}/marking/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    downloadMarkingSummary(data={}){
        let url = `${this.#api}/marking/export/summary`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);

    }
}